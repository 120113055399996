export default {
  "018920b1": [() => import(/* webpackChunkName: "018920b1" */ "@site/blog/2023-07-23-dockview-1.8.0.md?truncated=true"), "@site/blog/2023-07-23-dockview-1.8.0.md?truncated=true", require.resolveWeak("@site/blog/2023-07-23-dockview-1.8.0.md?truncated=true")],
  "01a85c17": [() => import(/* webpackChunkName: "01a85c17" */ "@theme/BlogTagsListPage"), "@theme/BlogTagsListPage", require.resolveWeak("@theme/BlogTagsListPage")],
  "025c71fc": [() => import(/* webpackChunkName: "025c71fc" */ "@site/docs/api/gridview/panelApi.mdx"), "@site/docs/api/gridview/panelApi.mdx", require.resolveWeak("@site/docs/api/gridview/panelApi.mdx")],
  "03625ec3": [() => import(/* webpackChunkName: "03625ec3" */ "@site/docs/api/dockview/panelApi.mdx"), "@site/docs/api/dockview/panelApi.mdx", require.resolveWeak("@site/docs/api/dockview/panelApi.mdx")],
  "0613c60c": [() => import(/* webpackChunkName: "0613c60c" */ "@site/blog/2022-10-04-dockview-1.5.2.mdx?truncated=true"), "@site/blog/2022-10-04-dockview-1.5.2.mdx?truncated=true", require.resolveWeak("@site/blog/2022-10-04-dockview-1.5.2.mdx?truncated=true")],
  "0769497c": [() => import(/* webpackChunkName: "0769497c" */ "@site/blog/2024-01-15-dockview-1.9.0.md"), "@site/blog/2024-01-15-dockview-1.9.0.md", require.resolveWeak("@site/blog/2024-01-15-dockview-1.9.0.md")],
  "080482b1": [() => import(/* webpackChunkName: "080482b1" */ "@site/blog/2022-05-11-dockview-1.4.1.mdx?truncated=true"), "@site/blog/2022-05-11-dockview-1.4.1.mdx?truncated=true", require.resolveWeak("@site/blog/2022-05-11-dockview-1.4.1.mdx?truncated=true")],
  "09e23a09": [() => import(/* webpackChunkName: "09e23a09" */ "~blog/default/blog-tags-release-page-3-4c9.json"), "~blog/default/blog-tags-release-page-3-4c9.json", require.resolveWeak("~blog/default/blog-tags-release-page-3-4c9.json")],
  "0aa325ab": [() => import(/* webpackChunkName: "0aa325ab" */ "@site/blog/2024-02-25-dockview-1.10.0.md"), "@site/blog/2024-02-25-dockview-1.10.0.md", require.resolveWeak("@site/blog/2024-02-25-dockview-1.10.0.md")],
  "0ac4dfe6": [() => import(/* webpackChunkName: "0ac4dfe6" */ "@site/blog/2024-10-12-dockview-1.17.2.md"), "@site/blog/2024-10-12-dockview-1.17.2.md", require.resolveWeak("@site/blog/2024-10-12-dockview-1.17.2.md")],
  "0b805377": [() => import(/* webpackChunkName: "0b805377" */ "@site/docs/api/dockview/options.mdx"), "@site/docs/api/dockview/options.mdx", require.resolveWeak("@site/docs/api/dockview/options.mdx")],
  "0c0f5dbc": [() => import(/* webpackChunkName: "0c0f5dbc" */ "@site/docs/api/splitview/panelApi.mdx"), "@site/docs/api/splitview/panelApi.mdx", require.resolveWeak("@site/docs/api/splitview/panelApi.mdx")],
  "0e982308": [() => import(/* webpackChunkName: "0e982308" */ "@site/blog/2025-03-17-dockview-4.2.0.md?truncated=true"), "@site/blog/2025-03-17-dockview-4.2.0.md?truncated=true", require.resolveWeak("@site/blog/2025-03-17-dockview-4.2.0.md?truncated=true")],
  "0fdf66f2": [() => import(/* webpackChunkName: "0fdf66f2" */ "@site/docs/overview/getStarted/installation.mdx"), "@site/docs/overview/getStarted/installation.mdx", require.resolveWeak("@site/docs/overview/getStarted/installation.mdx")],
  "10ffa965": [() => import(/* webpackChunkName: "10ffa965" */ "@site/docs/core/dnd/overview.mdx"), "@site/docs/core/dnd/overview.mdx", require.resolveWeak("@site/docs/core/dnd/overview.mdx")],
  "110e794f": [() => import(/* webpackChunkName: "110e794f" */ "@site/blog/2024-08-11-dockview-1.16.0.md?truncated=true"), "@site/blog/2024-08-11-dockview-1.16.0.md?truncated=true", require.resolveWeak("@site/blog/2024-08-11-dockview-1.16.0.md?truncated=true")],
  "11ce4159": [() => import(/* webpackChunkName: "11ce4159" */ "~blog/default/blog-page-5-c6b.json"), "~blog/default/blog-page-5-c6b.json", require.resolveWeak("~blog/default/blog-page-5-c6b.json")],
  "14c22dd6": [() => import(/* webpackChunkName: "14c22dd6" */ "@site/blog/2025-03-12-dockview-4.0.0.md"), "@site/blog/2025-03-12-dockview-4.0.0.md", require.resolveWeak("@site/blog/2025-03-12-dockview-4.0.0.md")],
  "16823569": [() => import(/* webpackChunkName: "16823569" */ "@site/docs/core/groups/hiddenHeader.mdx"), "@site/docs/core/groups/hiddenHeader.mdx", require.resolveWeak("@site/docs/core/groups/hiddenHeader.mdx")],
  "174bb33c": [() => import(/* webpackChunkName: "174bb33c" */ "@site/blog/2024-10-12-dockview-1.17.2.md?truncated=true"), "@site/blog/2024-10-12-dockview-1.17.2.md?truncated=true", require.resolveWeak("@site/blog/2024-10-12-dockview-1.17.2.md?truncated=true")],
  "17896441": [() => import(/* webpackChunkName: "17896441" */ "@theme/DocItem"), "@theme/DocItem", require.resolveWeak("@theme/DocItem")],
  "18a411a1": [() => import(/* webpackChunkName: "18a411a1" */ "@site/docs/other/gridview/overview.mdx"), "@site/docs/other/gridview/overview.mdx", require.resolveWeak("@site/docs/other/gridview/overview.mdx")],
  "18efe542": [() => import(/* webpackChunkName: "18efe542" */ "@site/blog/2024-05-28-dockview-1.14.1.md"), "@site/blog/2024-05-28-dockview-1.14.1.md", require.resolveWeak("@site/blog/2024-05-28-dockview-1.14.1.md")],
  "19f16a3b": [() => import(/* webpackChunkName: "19f16a3b" */ "@site/docs/overview/getStarted/contributing.mdx"), "@site/docs/overview/getStarted/contributing.mdx", require.resolveWeak("@site/docs/overview/getStarted/contributing.mdx")],
  "1a1255d9": [() => import(/* webpackChunkName: "1a1255d9" */ "@site/blog/2023-09-17-dockview-1.8.3.md?truncated=true"), "@site/blog/2023-09-17-dockview-1.8.3.md?truncated=true", require.resolveWeak("@site/blog/2023-09-17-dockview-1.8.3.md?truncated=true")],
  "1aa00813": [() => import(/* webpackChunkName: "1aa00813" */ "@site/blog/2024-03-03-dockview-1.10.1.md"), "@site/blog/2024-03-03-dockview-1.10.1.md", require.resolveWeak("@site/blog/2024-03-03-dockview-1.10.1.md")],
  "1c4c1c51": [() => import(/* webpackChunkName: "1c4c1c51" */ "@site/blog/2023-05-07-dockview-1.7.2.md?truncated=true"), "@site/blog/2023-05-07-dockview-1.7.2.md?truncated=true", require.resolveWeak("@site/blog/2023-05-07-dockview-1.7.2.md?truncated=true")],
  "1c5c516e": [() => import(/* webpackChunkName: "1c5c516e" */ "@site/blog/2024-05-23-dockview-1.14.0.md?truncated=true"), "@site/blog/2024-05-23-dockview-1.14.0.md?truncated=true", require.resolveWeak("@site/blog/2024-05-23-dockview-1.14.0.md?truncated=true")],
  "1df93b7f": [() => import(/* webpackChunkName: "1df93b7f" */ "@site/src/pages/index.tsx"), "@site/src/pages/index.tsx", require.resolveWeak("@site/src/pages/index.tsx")],
  "1ea9b37c": [() => import(/* webpackChunkName: "1ea9b37c" */ "@site/blog/2024-03-17-dockview-1.11.0.md?truncated=true"), "@site/blog/2024-03-17-dockview-1.11.0.md?truncated=true", require.resolveWeak("@site/blog/2024-03-17-dockview-1.11.0.md?truncated=true")],
  "1ef3ed16": [() => import(/* webpackChunkName: "1ef3ed16" */ "@site/blog/2023-07-24-dockview-1.8.2.md"), "@site/blog/2023-07-24-dockview-1.8.2.md", require.resolveWeak("@site/blog/2023-07-24-dockview-1.8.2.md")],
  "1f391b9e": [() => import(/* webpackChunkName: "1f391b9e" */ "@theme/MDXPage"), "@theme/MDXPage", require.resolveWeak("@theme/MDXPage")],
  "20435d4c": [() => import(/* webpackChunkName: "20435d4c" */ "@site/docs/core/scrollbars.mdx"), "@site/docs/core/scrollbars.mdx", require.resolveWeak("@site/docs/core/scrollbars.mdx")],
  "2050f0b2": [() => import(/* webpackChunkName: "2050f0b2" */ "@site/blog/2023-06-03-dockview-1.7.3.md"), "@site/blog/2023-06-03-dockview-1.7.3.md", require.resolveWeak("@site/blog/2023-06-03-dockview-1.7.3.md")],
  "211c047e": [() => import(/* webpackChunkName: "211c047e" */ "@site/blog/2024-12-20-dockview-2.1.1.md?truncated=true"), "@site/blog/2024-12-20-dockview-2.1.1.md?truncated=true", require.resolveWeak("@site/blog/2024-12-20-dockview-2.1.1.md?truncated=true")],
  "2120f8b9": [() => import(/* webpackChunkName: "2120f8b9" */ "@site/blog/2024-05-05-dockview-1.13.1.md?truncated=true"), "@site/blog/2024-05-05-dockview-1.13.1.md?truncated=true", require.resolveWeak("@site/blog/2024-05-05-dockview-1.13.1.md?truncated=true")],
  "21daebdb": [() => import(/* webpackChunkName: "21daebdb" */ "@site/blog/2024-08-13-dockview-1.16.1.md"), "@site/blog/2024-08-13-dockview-1.16.1.md", require.resolveWeak("@site/blog/2024-08-13-dockview-1.16.1.md")],
  "234a0a0f": [() => import(/* webpackChunkName: "234a0a0f" */ "@site/docs/core/dnd/external.mdx"), "@site/docs/core/dnd/external.mdx", require.resolveWeak("@site/docs/core/dnd/external.mdx")],
  "24220a77": [() => import(/* webpackChunkName: "24220a77" */ "@site/blog/2024-12-21-dockview-2.1.2.md"), "@site/blog/2024-12-21-dockview-2.1.2.md", require.resolveWeak("@site/blog/2024-12-21-dockview-2.1.2.md")],
  "265096f6": [() => import(/* webpackChunkName: "265096f6" */ "@site/blog/2024-06-08-dockview-1.14.2.md?truncated=true"), "@site/blog/2024-06-08-dockview-1.14.2.md?truncated=true", require.resolveWeak("@site/blog/2024-06-08-dockview-1.14.2.md?truncated=true")],
  "26fae105": [() => import(/* webpackChunkName: "26fae105" */ "@site/docs/core/panels/add.mdx"), "@site/docs/core/panels/add.mdx", require.resolveWeak("@site/docs/core/panels/add.mdx")],
  "2899f90d": [() => import(/* webpackChunkName: "2899f90d" */ "~blog/default/blog-tags-release-page-5-c56.json"), "~blog/default/blog-tags-release-page-5-c56.json", require.resolveWeak("~blog/default/blog-tags-release-page-5-c56.json")],
  "33424d8e": [() => import(/* webpackChunkName: "33424d8e" */ "@site/blog/2024-08-01-dockview-1.15.3.md?truncated=true"), "@site/blog/2024-08-01-dockview-1.15.3.md?truncated=true", require.resolveWeak("@site/blog/2024-08-01-dockview-1.15.3.md?truncated=true")],
  "3349f731": [() => import(/* webpackChunkName: "3349f731" */ "@site/blog/2023-04-11-dockview-1.7.1.md"), "@site/blog/2023-04-11-dockview-1.7.1.md", require.resolveWeak("@site/blog/2023-04-11-dockview-1.7.1.md")],
  "3381d90e": [() => import(/* webpackChunkName: "3381d90e" */ "@site/blog/2024-03-17-dockview-1.11.0.md"), "@site/blog/2024-03-17-dockview-1.11.0.md", require.resolveWeak("@site/blog/2024-03-17-dockview-1.11.0.md")],
  "34ac4eb7": [() => import(/* webpackChunkName: "34ac4eb7" */ "@site/docs/core/dnd/thirdParty.mdx"), "@site/docs/core/dnd/thirdParty.mdx", require.resolveWeak("@site/docs/core/dnd/thirdParty.mdx")],
  "351884eb": [() => import(/* webpackChunkName: "351884eb" */ "@site/blog/2023-03-25-dockview-1.7.0.md"), "@site/blog/2023-03-25-dockview-1.7.0.md", require.resolveWeak("@site/blog/2023-03-25-dockview-1.7.0.md")],
  "35f4d965": [() => import(/* webpackChunkName: "35f4d965" */ "@site/docs/api/gridview/api.mdx"), "@site/docs/api/gridview/api.mdx", require.resolveWeak("@site/docs/api/gridview/api.mdx")],
  "36ad4211": [() => import(/* webpackChunkName: "36ad4211" */ "@site/blog/2024-12-29-dockview-3.0.0.md"), "@site/blog/2024-12-29-dockview-3.0.0.md", require.resolveWeak("@site/blog/2024-12-29-dockview-3.0.0.md")],
  "377e5830": [() => import(/* webpackChunkName: "377e5830" */ "@site/docs/core/groups/maxmizedGroups.mdx"), "@site/docs/core/groups/maxmizedGroups.mdx", require.resolveWeak("@site/docs/core/groups/maxmizedGroups.mdx")],
  "3875b9e1": [() => import(/* webpackChunkName: "3875b9e1" */ "@site/blog/2022-05-16-dockview-1.4.2.mdx?truncated=true"), "@site/blog/2022-05-16-dockview-1.4.2.mdx?truncated=true", require.resolveWeak("@site/blog/2022-05-16-dockview-1.4.2.mdx?truncated=true")],
  "393be207": [() => import(/* webpackChunkName: "393be207" */ "@site/src/pages/markdown-page.md"), "@site/src/pages/markdown-page.md", require.resolveWeak("@site/src/pages/markdown-page.md")],
  "3c211144": [() => import(/* webpackChunkName: "3c211144" */ "@site/blog/2024-07-16-dockview-1.15.1.md"), "@site/blog/2024-07-16-dockview-1.15.1.md", require.resolveWeak("@site/blog/2024-07-16-dockview-1.15.1.md")],
  "3d21a8a9": [() => import(/* webpackChunkName: "3d21a8a9" */ "@site/blog/2025-03-16-dockview-4.1.0.md?truncated=true"), "@site/blog/2025-03-16-dockview-4.1.0.md?truncated=true", require.resolveWeak("@site/blog/2025-03-16-dockview-4.1.0.md?truncated=true")],
  "3dfb728c": [() => import(/* webpackChunkName: "3dfb728c" */ "@site/blog/2023-06-11-dockview-1.7.5.md"), "@site/blog/2023-06-11-dockview-1.7.5.md", require.resolveWeak("@site/blog/2023-06-11-dockview-1.7.5.md")],
  "40116192": [() => import(/* webpackChunkName: "40116192" */ "@site/blog/2024-05-28-dockview-1.14.1.md?truncated=true"), "@site/blog/2024-05-28-dockview-1.14.1.md?truncated=true", require.resolveWeak("@site/blog/2024-05-28-dockview-1.14.1.md?truncated=true")],
  "41159947": [() => import(/* webpackChunkName: "41159947" */ "@site/blog/2025-01-09-dockview-3.0.1.md?truncated=true"), "@site/blog/2025-01-09-dockview-3.0.1.md?truncated=true", require.resolveWeak("@site/blog/2025-01-09-dockview-3.0.1.md?truncated=true")],
  "41f446d8": [() => import(/* webpackChunkName: "41f446d8" */ "@site/blog/2025-01-11-dockview-3.0.2.md"), "@site/blog/2025-01-11-dockview-3.0.2.md", require.resolveWeak("@site/blog/2025-01-11-dockview-3.0.2.md")],
  "42d76c2d": [() => import(/* webpackChunkName: "42d76c2d" */ "@site/docs/core/groups/popoutGroups.mdx"), "@site/docs/core/groups/popoutGroups.mdx", require.resolveWeak("@site/docs/core/groups/popoutGroups.mdx")],
  "42f812af": [() => import(/* webpackChunkName: "42f812af" */ "@site/blog/2024-07-17-dockview-1.15.2.md"), "@site/blog/2024-07-17-dockview-1.15.2.md", require.resolveWeak("@site/blog/2024-07-17-dockview-1.15.2.md")],
  "44a5604a": [() => import(/* webpackChunkName: "44a5604a" */ "@site/blog/2024-12-22-dockview-2.1.3.md?truncated=true"), "@site/blog/2024-12-22-dockview-2.1.3.md?truncated=true", require.resolveWeak("@site/blog/2024-12-22-dockview-2.1.3.md?truncated=true")],
  "44ac4dbb": [() => import(/* webpackChunkName: "44ac4dbb" */ "~blog/default/blog-page-6-772.json"), "~blog/default/blog-page-6-772.json", require.resolveWeak("~blog/default/blog-page-6-772.json")],
  "4586cef8": [() => import(/* webpackChunkName: "4586cef8" */ "@site/blog/2024-03-15-dockview-1.10.2.md"), "@site/blog/2024-03-15-dockview-1.10.2.md", require.resolveWeak("@site/blog/2024-03-15-dockview-1.10.2.md")],
  "4764f7ec": [() => import(/* webpackChunkName: "4764f7ec" */ "~blog/default/blog-tags-release-page-4-cc6.json"), "~blog/default/blog-tags-release-page-4-cc6.json", require.resolveWeak("~blog/default/blog-tags-release-page-4-cc6.json")],
  "49b04166": [() => import(/* webpackChunkName: "49b04166" */ "@site/blog/2025-03-26-dockview-4.2.2.md"), "@site/blog/2025-03-26-dockview-4.2.2.md", require.resolveWeak("@site/blog/2025-03-26-dockview-4.2.2.md")],
  "49ca3828": [() => import(/* webpackChunkName: "49ca3828" */ "@site/blog/2024-11-03-dockview-2.0.0.md"), "@site/blog/2024-11-03-dockview-2.0.0.md", require.resolveWeak("@site/blog/2024-11-03-dockview-2.0.0.md")],
  "49e2b2ae": [() => import(/* webpackChunkName: "49e2b2ae" */ "@site/blog/2024-09-05-dockview-1.17.1.md?truncated=true"), "@site/blog/2024-09-05-dockview-1.17.1.md?truncated=true", require.resolveWeak("@site/blog/2024-09-05-dockview-1.17.1.md?truncated=true")],
  "4a27f0af": [() => import(/* webpackChunkName: "4a27f0af" */ "@site/blog/2022-06-12-dockview-1.5.0.mdx?truncated=true"), "@site/blog/2022-06-12-dockview-1.5.0.mdx?truncated=true", require.resolveWeak("@site/blog/2022-06-12-dockview-1.5.0.mdx?truncated=true")],
  "4d6c3daa": [() => import(/* webpackChunkName: "4d6c3daa" */ "@site/blog/2023-02-26-dockview-1.6.0.mdx?truncated=true"), "@site/blog/2023-02-26-dockview-1.6.0.mdx?truncated=true", require.resolveWeak("@site/blog/2023-02-26-dockview-1.6.0.mdx?truncated=true")],
  "4edc808e": [() => import(/* webpackChunkName: "4edc808e" */ "@site/docs/index.mdx"), "@site/docs/index.mdx", require.resolveWeak("@site/docs/index.mdx")],
  "4f320539": [() => import(/* webpackChunkName: "4f320539" */ "@site/src/pages/demo.tsx"), "@site/src/pages/demo.tsx", require.resolveWeak("@site/src/pages/demo.tsx")],
  "58768f16": [() => import(/* webpackChunkName: "58768f16" */ "@site/blog/2024-07-12-dockview-1.15.0.md"), "@site/blog/2024-07-12-dockview-1.15.0.md", require.resolveWeak("@site/blog/2024-07-12-dockview-1.15.0.md")],
  "58d5556a": [() => import(/* webpackChunkName: "58d5556a" */ "@site/blog/2024-09-05-dockview-1.17.1.md"), "@site/blog/2024-09-05-dockview-1.17.1.md", require.resolveWeak("@site/blog/2024-09-05-dockview-1.17.1.md")],
  "5acc0529": [() => import(/* webpackChunkName: "5acc0529" */ "@site/blog/2024-08-01-dockview-1.15.3.md"), "@site/blog/2024-08-01-dockview-1.15.3.md", require.resolveWeak("@site/blog/2024-08-01-dockview-1.15.3.md")],
  "5ce12484": [() => import(/* webpackChunkName: "5ce12484" */ "@site/blog/2024-06-08-dockview-1.14.2.md"), "@site/blog/2024-06-08-dockview-1.14.2.md", require.resolveWeak("@site/blog/2024-06-08-dockview-1.14.2.md")],
  "5d4879af": [() => import(/* webpackChunkName: "5d4879af" */ "~blog/default/blog-tags-release-page-5-c56-list.json"), "~blog/default/blog-tags-release-page-5-c56-list.json", require.resolveWeak("~blog/default/blog-tags-release-page-5-c56-list.json")],
  "5e3b423e": [() => import(/* webpackChunkName: "5e3b423e" */ "@site/blog/2025-02-02-dockview-3.1.0.md"), "@site/blog/2025-02-02-dockview-3.1.0.md", require.resolveWeak("@site/blog/2025-02-02-dockview-3.1.0.md")],
  "5e5e0475": [() => import(/* webpackChunkName: "5e5e0475" */ "~blog/default/blog-tags-release-page-6-1f5.json"), "~blog/default/blog-tags-release-page-6-1f5.json", require.resolveWeak("~blog/default/blog-tags-release-page-6-1f5.json")],
  "5e95c892": [() => import(/* webpackChunkName: "5e95c892" */ "@theme/DocsRoot"), "@theme/DocsRoot", require.resolveWeak("@theme/DocsRoot")],
  "5e9f5e1a": [() => import(/* webpackChunkName: "5e9f5e1a" */ "@generated/docusaurus.config"), "@generated/docusaurus.config", require.resolveWeak("@generated/docusaurus.config")],
  "63236b6e": [() => import(/* webpackChunkName: "63236b6e" */ "@site/docs/core/groups/move.mdx"), "@site/docs/core/groups/move.mdx", require.resolveWeak("@site/docs/core/groups/move.mdx")],
  "66505f87": [() => import(/* webpackChunkName: "66505f87" */ "@site/docs/core/dnd/dragAndDrop.mdx"), "@site/docs/core/dnd/dragAndDrop.mdx", require.resolveWeak("@site/docs/core/dnd/dragAndDrop.mdx")],
  "66b40244": [() => import(/* webpackChunkName: "66b40244" */ "@site/docs/core/panels/resizing.mdx"), "@site/docs/core/panels/resizing.mdx", require.resolveWeak("@site/docs/core/panels/resizing.mdx")],
  "6875c492": [() => import(/* webpackChunkName: "6875c492" */ "@theme/BlogTagsPostsPage"), "@theme/BlogTagsPostsPage", require.resolveWeak("@theme/BlogTagsPostsPage")],
  "6dbbc280": [() => import(/* webpackChunkName: "6dbbc280" */ "/home/runner/work/dockview/dockview/packages/docs/.docusaurus/docusaurus-plugin-content-blog/default/plugin-route-context-module-100.json"), "/home/runner/work/dockview/dockview/packages/docs/.docusaurus/docusaurus-plugin-content-blog/default/plugin-route-context-module-100.json", require.resolveWeak("/home/runner/work/dockview/dockview/packages/docs/.docusaurus/docusaurus-plugin-content-blog/default/plugin-route-context-module-100.json")],
  "6eb48177": [() => import(/* webpackChunkName: "6eb48177" */ "@site/docs/core/locked.mdx"), "@site/docs/core/locked.mdx", require.resolveWeak("@site/docs/core/locked.mdx")],
  "71f5195a": [() => import(/* webpackChunkName: "71f5195a" */ "@site/blog/2025-03-12-dockview-4.0.0.md?truncated=true"), "@site/blog/2025-03-12-dockview-4.0.0.md?truncated=true", require.resolveWeak("@site/blog/2025-03-12-dockview-4.0.0.md?truncated=true")],
  "72a37836": [() => import(/* webpackChunkName: "72a37836" */ "@site/docs/core/panels/rendering.mdx"), "@site/docs/core/panels/rendering.mdx", require.resolveWeak("@site/docs/core/panels/rendering.mdx")],
  "73bfd16c": [() => import(/* webpackChunkName: "73bfd16c" */ "~blog/default/blog-tags-release-page-2-ceb.json"), "~blog/default/blog-tags-release-page-2-ceb.json", require.resolveWeak("~blog/default/blog-tags-release-page-2-ceb.json")],
  "73cbb7be": [() => import(/* webpackChunkName: "73cbb7be" */ "@site/blog/2024-12-20-dockview-2.1.1.md"), "@site/blog/2024-12-20-dockview-2.1.1.md", require.resolveWeak("@site/blog/2024-12-20-dockview-2.1.1.md")],
  "77653806": [() => import(/* webpackChunkName: "77653806" */ "@site/docs/core/groups/floatingGroups.mdx"), "@site/docs/core/groups/floatingGroups.mdx", require.resolveWeak("@site/docs/core/groups/floatingGroups.mdx")],
  "79b88791": [() => import(/* webpackChunkName: "79b88791" */ "@site/blog/2024-04-27-dockview-1.13.0.md?truncated=true"), "@site/blog/2024-04-27-dockview-1.13.0.md?truncated=true", require.resolveWeak("@site/blog/2024-04-27-dockview-1.13.0.md?truncated=true")],
  "7a138dee": [() => import(/* webpackChunkName: "7a138dee" */ "@site/docs/api/dockview/groupApi.mdx"), "@site/docs/api/dockview/groupApi.mdx", require.resolveWeak("@site/docs/api/dockview/groupApi.mdx")],
  "7a83cb9c": [() => import(/* webpackChunkName: "7a83cb9c" */ "@site/docs/core/overview.mdx"), "@site/docs/core/overview.mdx", require.resolveWeak("@site/docs/core/overview.mdx")],
  "7b1c68f0": [() => import(/* webpackChunkName: "7b1c68f0" */ "@site/docs/api/paneview/options.mdx"), "@site/docs/api/paneview/options.mdx", require.resolveWeak("@site/docs/api/paneview/options.mdx")],
  "7b2547d0": [() => import(/* webpackChunkName: "7b2547d0" */ "@site/blog/2023-06-18-dockview-1.7.6.md"), "@site/blog/2023-06-18-dockview-1.7.6.md", require.resolveWeak("@site/blog/2023-06-18-dockview-1.7.6.md")],
  "7c9ab8eb": [() => import(/* webpackChunkName: "7c9ab8eb" */ "@site/blog/2025-03-14-dockview-4.0.1.md"), "@site/blog/2025-03-14-dockview-4.0.1.md", require.resolveWeak("@site/blog/2025-03-14-dockview-4.0.1.md")],
  "7d9726a8": [() => import(/* webpackChunkName: "7d9726a8" */ "~blog/default/blog-page-4-30b.json"), "~blog/default/blog-page-4-30b.json", require.resolveWeak("~blog/default/blog-page-4-30b.json")],
  "7da45616": [() => import(/* webpackChunkName: "7da45616" */ "@site/blog/2023-07-23-dockview-1.8.0.md"), "@site/blog/2023-07-23-dockview-1.8.0.md", require.resolveWeak("@site/blog/2023-07-23-dockview-1.8.0.md")],
  "7dc8bdfb": [() => import(/* webpackChunkName: "7dc8bdfb" */ "@site/docs/core/watermark.mdx"), "@site/docs/core/watermark.mdx", require.resolveWeak("@site/docs/core/watermark.mdx")],
  "7ea267e5": [() => import(/* webpackChunkName: "7ea267e5" */ "@site/docs/core/state/save.mdx"), "@site/docs/core/state/save.mdx", require.resolveWeak("@site/docs/core/state/save.mdx")],
  "7fc83237": [() => import(/* webpackChunkName: "7fc83237" */ "@site/blog/2022-05-16-dockview-1.4.2.mdx"), "@site/blog/2022-05-16-dockview-1.4.2.mdx", require.resolveWeak("@site/blog/2022-05-16-dockview-1.4.2.mdx")],
  "814f3328": [() => import(/* webpackChunkName: "814f3328" */ "~blog/default/blog-post-list-prop-default.json"), "~blog/default/blog-post-list-prop-default.json", require.resolveWeak("~blog/default/blog-post-list-prop-default.json")],
  "81d88ff9": [() => import(/* webpackChunkName: "81d88ff9" */ "@site/blog/2022-06-12-dockview-1.5.0.mdx"), "@site/blog/2022-06-12-dockview-1.5.0.mdx", require.resolveWeak("@site/blog/2022-06-12-dockview-1.5.0.mdx")],
  "82aa6db2": [() => import(/* webpackChunkName: "82aa6db2" */ "@site/blog/2024-12-21-dockview-2.1.2.md?truncated=true"), "@site/blog/2024-12-21-dockview-2.1.2.md?truncated=true", require.resolveWeak("@site/blog/2024-12-21-dockview-2.1.2.md?truncated=true")],
  "83d480e9": [() => import(/* webpackChunkName: "83d480e9" */ "~blog/default/blog-tags-release-b5c.json"), "~blog/default/blog-tags-release-b5c.json", require.resolveWeak("~blog/default/blog-tags-release-b5c.json")],
  "8409d26b": [() => import(/* webpackChunkName: "8409d26b" */ "@site/docs/core/groups/constraints.mdx"), "@site/docs/core/groups/constraints.mdx", require.resolveWeak("@site/docs/core/groups/constraints.mdx")],
  "85d90bf5": [() => import(/* webpackChunkName: "85d90bf5" */ "@site/blog/2024-01-23-dockview-1.9.2.md?truncated=true"), "@site/blog/2024-01-23-dockview-1.9.2.md?truncated=true", require.resolveWeak("@site/blog/2024-01-23-dockview-1.9.2.md?truncated=true")],
  "864e8769": [() => import(/* webpackChunkName: "864e8769" */ "@site/docs/api/paneview/panelApi.mdx"), "@site/docs/api/paneview/panelApi.mdx", require.resolveWeak("@site/docs/api/paneview/panelApi.mdx")],
  "8930f2be": [() => import(/* webpackChunkName: "8930f2be" */ "@site/blog/2022-10-04-dockview-1.5.2.mdx"), "@site/blog/2022-10-04-dockview-1.5.2.mdx", require.resolveWeak("@site/blog/2022-10-04-dockview-1.5.2.mdx")],
  "8a31554c": [() => import(/* webpackChunkName: "8a31554c" */ "@site/docs/api/paneview/api.mdx"), "@site/docs/api/paneview/api.mdx", require.resolveWeak("@site/docs/api/paneview/api.mdx")],
  "8a9e0b81": [() => import(/* webpackChunkName: "8a9e0b81" */ "@site/docs/api/splitview/api.mdx"), "@site/docs/api/splitview/api.mdx", require.resolveWeak("@site/docs/api/splitview/api.mdx")],
  "8d5e8a01": [() => import(/* webpackChunkName: "8d5e8a01" */ "@site/blog/2022-07-23-dockview-1.5.1.mdx"), "@site/blog/2022-07-23-dockview-1.5.1.mdx", require.resolveWeak("@site/blog/2022-07-23-dockview-1.5.1.mdx")],
  "8dc54173": [() => import(/* webpackChunkName: "8dc54173" */ "@site/blog/2024-12-17-dockview-2.1.0.md?truncated=true"), "@site/blog/2024-12-17-dockview-2.1.0.md?truncated=true", require.resolveWeak("@site/blog/2024-12-17-dockview-2.1.0.md?truncated=true")],
  "8eb4e46b": [() => import(/* webpackChunkName: "8eb4e46b" */ "~blog/default/blog-page-2-677.json"), "~blog/default/blog-page-2-677.json", require.resolveWeak("~blog/default/blog-page-2-677.json")],
  "90fee903": [() => import(/* webpackChunkName: "90fee903" */ "@site/blog/2025-03-18-dockview-4.2.1.md"), "@site/blog/2025-03-18-dockview-4.2.1.md", require.resolveWeak("@site/blog/2025-03-18-dockview-4.2.1.md")],
  "91312226": [() => import(/* webpackChunkName: "91312226" */ "@site/blog/2023-09-17-dockview-1.8.3.md"), "@site/blog/2023-09-17-dockview-1.8.3.md", require.resolveWeak("@site/blog/2023-09-17-dockview-1.8.3.md")],
  "9139917f": [() => import(/* webpackChunkName: "9139917f" */ "@site/blog/2023-07-24-dockview-1.8.2.md?truncated=true"), "@site/blog/2023-07-24-dockview-1.8.2.md?truncated=true", require.resolveWeak("@site/blog/2023-07-24-dockview-1.8.2.md?truncated=true")],
  "92999a1c": [() => import(/* webpackChunkName: "92999a1c" */ "~blog/default/blog-page-3-fd4.json"), "~blog/default/blog-page-3-fd4.json", require.resolveWeak("~blog/default/blog-page-3-fd4.json")],
  "935f2afb": [() => import(/* webpackChunkName: "935f2afb" */ "~docs/default/version-current-metadata-prop-751.json"), "~docs/default/version-current-metadata-prop-751.json", require.resolveWeak("~docs/default/version-current-metadata-prop-751.json")],
  "9393dc27": [() => import(/* webpackChunkName: "9393dc27" */ "@site/blog/2024-07-17-dockview-1.15.2.md?truncated=true"), "@site/blog/2024-07-17-dockview-1.15.2.md?truncated=true", require.resolveWeak("@site/blog/2024-07-17-dockview-1.15.2.md?truncated=true")],
  "9567769f": [() => import(/* webpackChunkName: "9567769f" */ "@site/blog/2024-07-16-dockview-1.15.1.md?truncated=true"), "@site/blog/2024-07-16-dockview-1.15.1.md?truncated=true", require.resolveWeak("@site/blog/2024-07-16-dockview-1.15.1.md?truncated=true")],
  "95b3954e": [() => import(/* webpackChunkName: "95b3954e" */ "@site/blog/2024-01-23-dockview-1.9.2.md"), "@site/blog/2024-01-23-dockview-1.9.2.md", require.resolveWeak("@site/blog/2024-01-23-dockview-1.9.2.md")],
  "96c5d48d": [() => import(/* webpackChunkName: "96c5d48d" */ "~blog/default/blog-tags-release-page-4-cc6-list.json"), "~blog/default/blog-tags-release-page-4-cc6-list.json", require.resolveWeak("~blog/default/blog-tags-release-page-4-cc6-list.json")],
  "96d196d8": [() => import(/* webpackChunkName: "96d196d8" */ "@site/blog/2025-02-02-dockview-3.1.0.md?truncated=true"), "@site/blog/2025-02-02-dockview-3.1.0.md?truncated=true", require.resolveWeak("@site/blog/2025-02-02-dockview-3.1.0.md?truncated=true")],
  "97ecaf58": [() => import(/* webpackChunkName: "97ecaf58" */ "@site/blog/2023-10-06-dockview-1.8.4.md?truncated=true"), "@site/blog/2023-10-06-dockview-1.8.4.md?truncated=true", require.resolveWeak("@site/blog/2023-10-06-dockview-1.8.4.md?truncated=true")],
  "9933ed81": [() => import(/* webpackChunkName: "9933ed81" */ "@site/docs/overview/getStarted/theme.mdx"), "@site/docs/overview/getStarted/theme.mdx", require.resolveWeak("@site/docs/overview/getStarted/theme.mdx")],
  "9a1bdfae": [() => import(/* webpackChunkName: "9a1bdfae" */ "@site/docs/core/groups/controls.mdx"), "@site/docs/core/groups/controls.mdx", require.resolveWeak("@site/docs/core/groups/controls.mdx")],
  "9c021584": [() => import(/* webpackChunkName: "9c021584" */ "~blog/default/blog-tags-release-b5c-list.json"), "~blog/default/blog-tags-release-b5c-list.json", require.resolveWeak("~blog/default/blog-tags-release-b5c-list.json")],
  "9c3e7434": [() => import(/* webpackChunkName: "9c3e7434" */ "@site/blog/2025-03-14-dockview-4.0.1.md?truncated=true"), "@site/blog/2025-03-14-dockview-4.0.1.md?truncated=true", require.resolveWeak("@site/blog/2025-03-14-dockview-4.0.1.md?truncated=true")],
  "9e4087bc": [() => import(/* webpackChunkName: "9e4087bc" */ "@theme/BlogArchivePage"), "@theme/BlogArchivePage", require.resolveWeak("@theme/BlogArchivePage")],
  "9ebd23b1": [() => import(/* webpackChunkName: "9ebd23b1" */ "@site/blog/2024-12-17-dockview-2.1.0.md"), "@site/blog/2024-12-17-dockview-2.1.0.md", require.resolveWeak("@site/blog/2024-12-17-dockview-2.1.0.md")],
  "9f994a83": [() => import(/* webpackChunkName: "9f994a83" */ "@site/blog/2024-04-15-dockview-1.12.0.md"), "@site/blog/2024-04-15-dockview-1.12.0.md", require.resolveWeak("@site/blog/2024-04-15-dockview-1.12.0.md")],
  "a25616b5": [() => import(/* webpackChunkName: "a25616b5" */ "@site/blog/2025-03-16-dockview-4.1.0.md"), "@site/blog/2025-03-16-dockview-4.1.0.md", require.resolveWeak("@site/blog/2025-03-16-dockview-4.1.0.md")],
  "a37708fb": [() => import(/* webpackChunkName: "a37708fb" */ "@site/docs/core/state/load.mdx"), "@site/docs/core/state/load.mdx", require.resolveWeak("@site/docs/core/state/load.mdx")],
  "a3c11fa9": [() => import(/* webpackChunkName: "a3c11fa9" */ "@site/blog/2023-06-10-dockview-1.7.4.md?truncated=true"), "@site/blog/2023-06-10-dockview-1.7.4.md?truncated=true", require.resolveWeak("@site/blog/2023-06-10-dockview-1.7.4.md?truncated=true")],
  "a6aa9e1f": [() => import(/* webpackChunkName: "a6aa9e1f" */ "@theme/BlogListPage"), "@theme/BlogListPage", require.resolveWeak("@theme/BlogListPage")],
  "a7023ddc": [() => import(/* webpackChunkName: "a7023ddc" */ "~blog/default/blog-tags-tags-4c2.json"), "~blog/default/blog-tags-tags-4c2.json", require.resolveWeak("~blog/default/blog-tags-tags-4c2.json")],
  "a799a200": [() => import(/* webpackChunkName: "a799a200" */ "@site/blog/2024-01-20-dockview-1.9.1.md?truncated=true"), "@site/blog/2024-01-20-dockview-1.9.1.md?truncated=true", require.resolveWeak("@site/blog/2024-01-20-dockview-1.9.1.md?truncated=true")],
  "a7bd4aaa": [() => import(/* webpackChunkName: "a7bd4aaa" */ "@theme/DocVersionRoot"), "@theme/DocVersionRoot", require.resolveWeak("@theme/DocVersionRoot")],
  "a900f9d1": [() => import(/* webpackChunkName: "a900f9d1" */ "@site/blog/2024-12-23-dockview-2.1.4.md"), "@site/blog/2024-12-23-dockview-2.1.4.md", require.resolveWeak("@site/blog/2024-12-23-dockview-2.1.4.md")],
  "a94703ab": [() => import(/* webpackChunkName: "a94703ab" */ "@theme/DocRoot"), "@theme/DocRoot", require.resolveWeak("@theme/DocRoot")],
  "aa1d0d4b": [() => import(/* webpackChunkName: "aa1d0d4b" */ "@site/docs/core/panels/register.mdx"), "@site/docs/core/panels/register.mdx", require.resolveWeak("@site/docs/core/panels/register.mdx")],
  "aa6ab096": [() => import(/* webpackChunkName: "aa6ab096" */ "@site/blog/2024-05-23-dockview-1.14.0.md"), "@site/blog/2024-05-23-dockview-1.14.0.md", require.resolveWeak("@site/blog/2024-05-23-dockview-1.14.0.md")],
  "acee1d6f": [() => import(/* webpackChunkName: "acee1d6f" */ "@site/docs/core/panels/tabs.mdx"), "@site/docs/core/panels/tabs.mdx", require.resolveWeak("@site/docs/core/panels/tabs.mdx")],
  "b298070a": [() => import(/* webpackChunkName: "b298070a" */ "@site/blog/2024-12-22-dockview-2.1.3.md"), "@site/blog/2024-12-22-dockview-2.1.3.md", require.resolveWeak("@site/blog/2024-12-22-dockview-2.1.3.md")],
  "b2b675dd": [() => import(/* webpackChunkName: "b2b675dd" */ "~blog/default/blog-c06.json"), "~blog/default/blog-c06.json", require.resolveWeak("~blog/default/blog-c06.json")],
  "b2f554cd": [() => import(/* webpackChunkName: "b2f554cd" */ "~blog/default/blog-archive-80c.json"), "~blog/default/blog-archive-80c.json", require.resolveWeak("~blog/default/blog-archive-80c.json")],
  "b31df0b0": [() => import(/* webpackChunkName: "b31df0b0" */ "~blog/default/blog-tags-release-page-3-4c9-list.json"), "~blog/default/blog-tags-release-page-3-4c9-list.json", require.resolveWeak("~blog/default/blog-tags-release-page-3-4c9-list.json")],
  "b44410fe": [() => import(/* webpackChunkName: "b44410fe" */ "@site/blog/2025-01-09-dockview-3.0.1.md"), "@site/blog/2025-01-09-dockview-3.0.1.md", require.resolveWeak("@site/blog/2025-01-09-dockview-3.0.1.md")],
  "b6330b1f": [() => import(/* webpackChunkName: "b6330b1f" */ "@site/blog/2024-12-29-dockview-3.0.0.md?truncated=true"), "@site/blog/2024-12-29-dockview-3.0.0.md?truncated=true", require.resolveWeak("@site/blog/2024-12-29-dockview-3.0.0.md?truncated=true")],
  "b64cb258": [() => import(/* webpackChunkName: "b64cb258" */ "@site/docs/core/panels/remove.mdx"), "@site/docs/core/panels/remove.mdx", require.resolveWeak("@site/docs/core/panels/remove.mdx")],
  "b731efc8": [() => import(/* webpackChunkName: "b731efc8" */ "@site/docs/api/dockview/overview.mdx"), "@site/docs/api/dockview/overview.mdx", require.resolveWeak("@site/docs/api/dockview/overview.mdx")],
  "b8649295": [() => import(/* webpackChunkName: "b8649295" */ "@site/blog/2023-10-06-dockview-1.8.5.md?truncated=true"), "@site/blog/2023-10-06-dockview-1.8.5.md?truncated=true", require.resolveWeak("@site/blog/2023-10-06-dockview-1.8.5.md?truncated=true")],
  "b8d3737c": [() => import(/* webpackChunkName: "b8d3737c" */ "@site/blog/2025-03-26-dockview-4.2.2.md?truncated=true"), "@site/blog/2025-03-26-dockview-4.2.2.md?truncated=true", require.resolveWeak("@site/blog/2025-03-26-dockview-4.2.2.md?truncated=true")],
  "b8ee4fe7": [() => import(/* webpackChunkName: "b8ee4fe7" */ "@site/blog/2023-10-06-dockview-1.8.5.md"), "@site/blog/2023-10-06-dockview-1.8.5.md", require.resolveWeak("@site/blog/2023-10-06-dockview-1.8.5.md")],
  "b9248980": [() => import(/* webpackChunkName: "b9248980" */ "@site/blog/2023-06-11-dockview-1.7.5.md?truncated=true"), "@site/blog/2023-06-11-dockview-1.7.5.md?truncated=true", require.resolveWeak("@site/blog/2023-06-11-dockview-1.7.5.md?truncated=true")],
  "b9f0ab0f": [() => import(/* webpackChunkName: "b9f0ab0f" */ "@site/blog/2025-03-18-dockview-4.2.1.md?truncated=true"), "@site/blog/2025-03-18-dockview-4.2.1.md?truncated=true", require.resolveWeak("@site/blog/2025-03-18-dockview-4.2.1.md?truncated=true")],
  "baa6b714": [() => import(/* webpackChunkName: "baa6b714" */ "@site/blog/2022-05-26-dockview-1.4.3.mdx"), "@site/blog/2022-05-26-dockview-1.4.3.mdx", require.resolveWeak("@site/blog/2022-05-26-dockview-1.4.3.mdx")],
  "bbc50775": [() => import(/* webpackChunkName: "bbc50775" */ "@site/docs/other/splitview/overview.mdx"), "@site/docs/other/splitview/overview.mdx", require.resolveWeak("@site/docs/other/splitview/overview.mdx")],
  "bd82c730": [() => import(/* webpackChunkName: "bd82c730" */ "@site/blog/2023-06-18-dockview-1.7.6.md?truncated=true"), "@site/blog/2023-06-18-dockview-1.7.6.md?truncated=true", require.resolveWeak("@site/blog/2023-06-18-dockview-1.7.6.md?truncated=true")],
  "be94ed75": [() => import(/* webpackChunkName: "be94ed75" */ "@site/docs/advanced/iframe.mdx"), "@site/docs/advanced/iframe.mdx", require.resolveWeak("@site/docs/advanced/iframe.mdx")],
  "be999520": [() => import(/* webpackChunkName: "be999520" */ "/home/runner/work/dockview/dockview/packages/docs/.docusaurus/docusaurus-plugin-content-docs/default/plugin-route-context-module-100.json"), "/home/runner/work/dockview/dockview/packages/docs/.docusaurus/docusaurus-plugin-content-docs/default/plugin-route-context-module-100.json", require.resolveWeak("/home/runner/work/dockview/dockview/packages/docs/.docusaurus/docusaurus-plugin-content-docs/default/plugin-route-context-module-100.json")],
  "c0efa0b3": [() => import(/* webpackChunkName: "c0efa0b3" */ "@site/blog/2024-04-27-dockview-1.13.0.md"), "@site/blog/2024-04-27-dockview-1.13.0.md", require.resolveWeak("@site/blog/2024-04-27-dockview-1.13.0.md")],
  "c1834088": [() => import(/* webpackChunkName: "c1834088" */ "@site/blog/2025-02-09-dockview-3.1.1.md?truncated=true"), "@site/blog/2025-02-09-dockview-3.1.1.md?truncated=true", require.resolveWeak("@site/blog/2025-02-09-dockview-3.1.1.md?truncated=true")],
  "c5c39f25": [() => import(/* webpackChunkName: "c5c39f25" */ "@site/blog/2022-05-26-dockview-1.4.3.mdx?truncated=true"), "@site/blog/2022-05-26-dockview-1.4.3.mdx?truncated=true", require.resolveWeak("@site/blog/2022-05-26-dockview-1.4.3.mdx?truncated=true")],
  "c753a914": [() => import(/* webpackChunkName: "c753a914" */ "@site/blog/2023-06-10-dockview-1.7.4.md"), "@site/blog/2023-06-10-dockview-1.7.4.md", require.resolveWeak("@site/blog/2023-06-10-dockview-1.7.4.md")],
  "c775102a": [() => import(/* webpackChunkName: "c775102a" */ "@site/blog/2023-04-11-dockview-1.7.1.md?truncated=true"), "@site/blog/2023-04-11-dockview-1.7.1.md?truncated=true", require.resolveWeak("@site/blog/2023-04-11-dockview-1.7.1.md?truncated=true")],
  "ccc49370": [() => import(/* webpackChunkName: "ccc49370" */ "@theme/BlogPostPage"), "@theme/BlogPostPage", require.resolveWeak("@theme/BlogPostPage")],
  "ce438b21": [() => import(/* webpackChunkName: "ce438b21" */ "@site/blog/2023-02-26-dockview-1.6.0.mdx"), "@site/blog/2023-02-26-dockview-1.6.0.mdx", require.resolveWeak("@site/blog/2023-02-26-dockview-1.6.0.mdx")],
  "ceb1118e": [() => import(/* webpackChunkName: "ceb1118e" */ "@site/docs/other/tabview.mdx"), "@site/docs/other/tabview.mdx", require.resolveWeak("@site/docs/other/tabview.mdx")],
  "cef009fa": [() => import(/* webpackChunkName: "cef009fa" */ "@site/docs/other/paneview/overview.mdx"), "@site/docs/other/paneview/overview.mdx", require.resolveWeak("@site/docs/other/paneview/overview.mdx")],
  "d18781b8": [() => import(/* webpackChunkName: "d18781b8" */ "@site/blog/2025-03-17-dockview-4.2.0.md"), "@site/blog/2025-03-17-dockview-4.2.0.md", require.resolveWeak("@site/blog/2025-03-17-dockview-4.2.0.md")],
  "d1fc0f3a": [() => import(/* webpackChunkName: "d1fc0f3a" */ "@site/blog/2024-08-13-dockview-1.16.1.md?truncated=true"), "@site/blog/2024-08-13-dockview-1.16.1.md?truncated=true", require.resolveWeak("@site/blog/2024-08-13-dockview-1.16.1.md?truncated=true")],
  "d21d1339": [() => import(/* webpackChunkName: "d21d1339" */ "@site/blog/2024-09-05-dockview-1.17.0.md?truncated=true"), "@site/blog/2024-09-05-dockview-1.17.0.md?truncated=true", require.resolveWeak("@site/blog/2024-09-05-dockview-1.17.0.md?truncated=true")],
  "d350f0e3": [() => import(/* webpackChunkName: "d350f0e3" */ "@site/blog/2025-02-12-dockview-3.2.0.md?truncated=true"), "@site/blog/2025-02-12-dockview-3.2.0.md?truncated=true", require.resolveWeak("@site/blog/2025-02-12-dockview-3.2.0.md?truncated=true")],
  "d451a7bc": [() => import(/* webpackChunkName: "d451a7bc" */ "@site/blog/2025-02-12-dockview-3.2.0.md"), "@site/blog/2025-02-12-dockview-3.2.0.md", require.resolveWeak("@site/blog/2025-02-12-dockview-3.2.0.md")],
  "d59f45b0": [() => import(/* webpackChunkName: "d59f45b0" */ "@site/blog/2023-06-03-dockview-1.7.3.md?truncated=true"), "@site/blog/2023-06-03-dockview-1.7.3.md?truncated=true", require.resolveWeak("@site/blog/2023-06-03-dockview-1.7.3.md?truncated=true")],
  "d65aeb8d": [() => import(/* webpackChunkName: "d65aeb8d" */ "@site/blog/2024-03-15-dockview-1.10.2.md?truncated=true"), "@site/blog/2024-03-15-dockview-1.10.2.md?truncated=true", require.resolveWeak("@site/blog/2024-03-15-dockview-1.10.2.md?truncated=true")],
  "d6af3e03": [() => import(/* webpackChunkName: "d6af3e03" */ "~blog/default/blog-tags-release-page-6-1f5-list.json"), "~blog/default/blog-tags-release-page-6-1f5-list.json", require.resolveWeak("~blog/default/blog-tags-release-page-6-1f5-list.json")],
  "d6dd8050": [() => import(/* webpackChunkName: "d6dd8050" */ "@site/blog/2024-02-25-dockview-1.10.0.md?truncated=true"), "@site/blog/2024-02-25-dockview-1.10.0.md?truncated=true", require.resolveWeak("@site/blog/2024-02-25-dockview-1.10.0.md?truncated=true")],
  "d732a5c2": [() => import(/* webpackChunkName: "d732a5c2" */ "@site/docs/core/panels/update.mdx"), "@site/docs/core/panels/update.mdx", require.resolveWeak("@site/docs/core/panels/update.mdx")],
  "d797f9a2": [() => import(/* webpackChunkName: "d797f9a2" */ "@site/docs/api/gridview/options.mdx"), "@site/docs/api/gridview/options.mdx", require.resolveWeak("@site/docs/api/gridview/options.mdx")],
  "d7add774": [() => import(/* webpackChunkName: "d7add774" */ "@site/blog/2025-01-11-dockview-3.0.2.md?truncated=true"), "@site/blog/2025-01-11-dockview-3.0.2.md?truncated=true", require.resolveWeak("@site/blog/2025-01-11-dockview-3.0.2.md?truncated=true")],
  "db575f29": [() => import(/* webpackChunkName: "db575f29" */ "@site/docs/advanced/nested.mdx"), "@site/docs/advanced/nested.mdx", require.resolveWeak("@site/docs/advanced/nested.mdx")],
  "de4d3a47": [() => import(/* webpackChunkName: "de4d3a47" */ "@site/blog/2024-08-11-dockview-1.16.0.md"), "@site/blog/2024-08-11-dockview-1.16.0.md", require.resolveWeak("@site/blog/2024-08-11-dockview-1.16.0.md")],
  "e0d47fcd": [() => import(/* webpackChunkName: "e0d47fcd" */ "@site/blog/2023-10-06-dockview-1.8.4.md"), "@site/blog/2023-10-06-dockview-1.8.4.md", require.resolveWeak("@site/blog/2023-10-06-dockview-1.8.4.md")],
  "e1fcafe5": [() => import(/* webpackChunkName: "e1fcafe5" */ "@site/blog/2022-05-11-dockview-1.4.1.mdx"), "@site/blog/2022-05-11-dockview-1.4.1.mdx", require.resolveWeak("@site/blog/2022-05-11-dockview-1.4.1.mdx")],
  "e2c193af": [() => import(/* webpackChunkName: "e2c193af" */ "@site/blog/2024-07-12-dockview-1.15.0.md?truncated=true"), "@site/blog/2024-07-12-dockview-1.15.0.md?truncated=true", require.resolveWeak("@site/blog/2024-07-12-dockview-1.15.0.md?truncated=true")],
  "e6402641": [() => import(/* webpackChunkName: "e6402641" */ "@site/blog/2024-05-05-dockview-1.13.1.md"), "@site/blog/2024-05-05-dockview-1.13.1.md", require.resolveWeak("@site/blog/2024-05-05-dockview-1.13.1.md")],
  "e67bc143": [() => import(/* webpackChunkName: "e67bc143" */ "@site/blog/2023-03-25-dockview-1.7.0.md?truncated=true"), "@site/blog/2023-03-25-dockview-1.7.0.md?truncated=true", require.resolveWeak("@site/blog/2023-03-25-dockview-1.7.0.md?truncated=true")],
  "e8601cfe": [() => import(/* webpackChunkName: "e8601cfe" */ "@site/blog/2023-05-07-dockview-1.7.2.md"), "@site/blog/2023-05-07-dockview-1.7.2.md", require.resolveWeak("@site/blog/2023-05-07-dockview-1.7.2.md")],
  "eb299cb3": [() => import(/* webpackChunkName: "eb299cb3" */ "~blog/default/blog-tags-release-page-2-ceb-list.json"), "~blog/default/blog-tags-release-page-2-ceb-list.json", require.resolveWeak("~blog/default/blog-tags-release-page-2-ceb-list.json")],
  "f1be756a": [() => import(/* webpackChunkName: "f1be756a" */ "@site/docs/advanced/keyboard.mdx"), "@site/docs/advanced/keyboard.mdx", require.resolveWeak("@site/docs/advanced/keyboard.mdx")],
  "f329feb9": [() => import(/* webpackChunkName: "f329feb9" */ "@site/blog/2024-12-23-dockview-2.1.4.md?truncated=true"), "@site/blog/2024-12-23-dockview-2.1.4.md?truncated=true", require.resolveWeak("@site/blog/2024-12-23-dockview-2.1.4.md?truncated=true")],
  "f56af1b7": [() => import(/* webpackChunkName: "f56af1b7" */ "@site/docs/core/groups/locked.mdx"), "@site/docs/core/groups/locked.mdx", require.resolveWeak("@site/docs/core/groups/locked.mdx")],
  "f59d8d36": [() => import(/* webpackChunkName: "f59d8d36" */ "/home/runner/work/dockview/dockview/packages/docs/.docusaurus/docusaurus-plugin-content-pages/default/plugin-route-context-module-100.json"), "/home/runner/work/dockview/dockview/packages/docs/.docusaurus/docusaurus-plugin-content-pages/default/plugin-route-context-module-100.json", require.resolveWeak("/home/runner/work/dockview/dockview/packages/docs/.docusaurus/docusaurus-plugin-content-pages/default/plugin-route-context-module-100.json")],
  "f746c2b3": [() => import(/* webpackChunkName: "f746c2b3" */ "@site/blog/2024-01-20-dockview-1.9.1.md"), "@site/blog/2024-01-20-dockview-1.9.1.md", require.resolveWeak("@site/blog/2024-01-20-dockview-1.9.1.md")],
  "f74f5c15": [() => import(/* webpackChunkName: "f74f5c15" */ "@site/blog/2024-03-03-dockview-1.10.1.md?truncated=true"), "@site/blog/2024-03-03-dockview-1.10.1.md?truncated=true", require.resolveWeak("@site/blog/2024-03-03-dockview-1.10.1.md?truncated=true")],
  "f7998abb": [() => import(/* webpackChunkName: "f7998abb" */ "@site/blog/2022-07-23-dockview-1.5.1.mdx?truncated=true"), "@site/blog/2022-07-23-dockview-1.5.1.mdx?truncated=true", require.resolveWeak("@site/blog/2022-07-23-dockview-1.5.1.mdx?truncated=true")],
  "f7aa18fd": [() => import(/* webpackChunkName: "f7aa18fd" */ "@site/blog/2024-01-15-dockview-1.9.0.md?truncated=true"), "@site/blog/2024-01-15-dockview-1.9.0.md?truncated=true", require.resolveWeak("@site/blog/2024-01-15-dockview-1.9.0.md?truncated=true")],
  "f959b8ee": [() => import(/* webpackChunkName: "f959b8ee" */ "@site/docs/core/dnd/disable.mdx"), "@site/docs/core/dnd/disable.mdx", require.resolveWeak("@site/docs/core/dnd/disable.mdx")],
  "f9ba8190": [() => import(/* webpackChunkName: "f9ba8190" */ "@site/blog/2024-11-03-dockview-2.0.0.md?truncated=true"), "@site/blog/2024-11-03-dockview-2.0.0.md?truncated=true", require.resolveWeak("@site/blog/2024-11-03-dockview-2.0.0.md?truncated=true")],
  "fa5a8307": [() => import(/* webpackChunkName: "fa5a8307" */ "@site/blog/2024-09-05-dockview-1.17.0.md"), "@site/blog/2024-09-05-dockview-1.17.0.md", require.resolveWeak("@site/blog/2024-09-05-dockview-1.17.0.md")],
  "fa5d1f49": [() => import(/* webpackChunkName: "fa5d1f49" */ "@site/docs/core/panels/move.mdx"), "@site/docs/core/panels/move.mdx", require.resolveWeak("@site/docs/core/panels/move.mdx")],
  "fab591c3": [() => import(/* webpackChunkName: "fab591c3" */ "@site/blog/2025-02-09-dockview-3.1.1.md"), "@site/blog/2025-02-09-dockview-3.1.1.md", require.resolveWeak("@site/blog/2025-02-09-dockview-3.1.1.md")],
  "fc470f21": [() => import(/* webpackChunkName: "fc470f21" */ "@site/blog/2024-04-15-dockview-1.12.0.md?truncated=true"), "@site/blog/2024-04-15-dockview-1.12.0.md?truncated=true", require.resolveWeak("@site/blog/2024-04-15-dockview-1.12.0.md?truncated=true")],
  "fd4c59dc": [() => import(/* webpackChunkName: "fd4c59dc" */ "@site/docs/core/groups/resizing.mdx"), "@site/docs/core/groups/resizing.mdx", require.resolveWeak("@site/docs/core/groups/resizing.mdx")],
  "fd863bf9": [() => import(/* webpackChunkName: "fd863bf9" */ "@site/docs/advanced/advanced.mdx"), "@site/docs/advanced/advanced.mdx", require.resolveWeak("@site/docs/advanced/advanced.mdx")],
  "fdfe2350": [() => import(/* webpackChunkName: "fdfe2350" */ "@site/docs/api/splitview/options.mdx"), "@site/docs/api/splitview/options.mdx", require.resolveWeak("@site/docs/api/splitview/options.mdx")],};
